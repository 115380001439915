/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyByNt9iZJORrP852SA_sUHb7DSzqCZw7j4",
  "appId": "1:153432054044:web:97e3d92b866f7570ef02d9",
  "authDomain": "schooldog-i-carrollton-ga.firebaseapp.com",
  "measurementId": "G-HFQYZVFXC3",
  "messagingSenderId": "153432054044",
  "project": "schooldog-i-carrollton-ga",
  "projectId": "schooldog-i-carrollton-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-carrollton-ga.appspot.com"
}
